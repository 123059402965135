import React from 'react';
import Layout from '../layouts/layout';

export default function Accessibility() {
	return (
		<Layout title="Accessibility">
			<h1>Privacy</h1>

			<p>
				We do use analytics, but data is anonymized and friendly to
				privacy laws such as GDPR and CCPA. We don't have to show a
				popup or ask you, we honor your privacy by default.
			</p>

			<p>
				We do not sell, share, or use traffic information for purposes
				other than improving our business and website.
			</p>
		</Layout>
	);
}
